import React, { useContext, useEffect, useState } from "react";
import SEO from "../components/seo";
import AlgoliaPlaces from "algolia-places-react";
import Switch from "react-switch";
import "./create-group.scss";
import RadiusSlider from "../components/slider";
import Button from "../components/button";
import { navigate } from "gatsby";
import { Context } from "../context/store";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { Loading } from "../components/loading";

const MAX_SWIPES_NEEDED_TO_MATCH = 10;
const MIN_SWIPES_NEEDED_TO_MATCH = 1;

const CREATE_GROUP = gql`
  mutation insertGroup(
    $lat: float8
    $long: float8
    $max_price: Int
    $min_price: Int
    $radius: Int
    $swipes_needed_to_match: Int
  ) {
    insert_groups(
      objects: {
        lat: $lat
        long: $long
        min_price: $min_price
        max_price: $max_price
        radius: $radius
        swipes_needed_to_match: $swipes_needed_to_match
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const GET_TOKEN = gql`
  query getToken($id: Int) {
    groups(where: { id: { _eq: $id } }) {
      token
    }
  }
`;

const createGroup = () => {
  const [address, setAddress] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });
  const [isChecked, setIsChecked] = useState(true);
  const [locationPermission, setLocationPermission] = useState(true);
  const [values, setValues] = useState<number[]>([5]);
  const [update, setUpdate] = useState<number[]>([5]);
  const [maxPrice, setMaxPrice] = useState("3");
  const [swipesNeededToMatch, setSwipesNeededToMatch] = useState("2");

  const [
    createGroup,
    { data: group, called: groupCalled, error: groupError },
  ] = useMutation(CREATE_GROUP);
  const [
    getToken,
    {
      called: tokenCalled,
      data: tokenData,
      stopPolling: tokenStopPolling,
      error: tokenError,
    },
  ] = useLazyQuery(GET_TOKEN, { pollInterval: 500 });

  const { state } = useContext(Context);

  useEffect(() => {
    let input = document.getElementsByClassName(
      "ap-input"
    )[0] as HTMLInputElement;
    input.style.backgroundColor =
      state.theme === "light" ? "white" : "hsl(209, 18%, 30%)";
    input.style.borderColor =
      state.theme === "light" ? "#CCC" : "hsl(209, 20%, 25%)";
    input.style.color = state.theme === "light" ? "black" : "white";

    input.classList.remove("input-placeholder-light");
    input.classList.remove("input-placeholder-dark");

    input.classList.toggle(
      state.theme === "light"
        ? "input-placeholder-light"
        : "input-placeholder-dark"
    );

    let dropdown = document.getElementsByClassName(
      "ap-dropdown-menu"
    )[0] as HTMLSpanElement;
    dropdown.style.background =
      state.theme === "light" ? "white" : "hsl(209, 18%, 30%)";
  }, [state]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setAddress({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      error => {
        if (error) {
          setIsChecked(false);
          setLocationPermission(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (tokenData?.groups[0]?.token) {
      tokenStopPolling?.();
      navigate(`/session/${tokenData?.groups[0]?.token}`);
    }
  }, [tokenData]);

  useEffect(() => {
    if (group?.insert_groups?.returning[0]) {
      getToken({ variables: { id: group.insert_groups.returning[0].id } });
    }
  }, [group]);

  useEffect(() => {
    if (isChecked && locationPermission) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setAddress({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => {
          if (error) {
            setIsChecked(false);
            setLocationPermission(false);
          }
        }
      );
    } else {
      setAddress({ lat: null, lng: null });
    }
  }, [isChecked]);

  const onUpdate = (u: ReadonlyArray<number>) => {
    setUpdate([...u]);
  };

  const onChange = (v: ReadonlyArray<number>) => {
    setValues([...v]);
  };

  const onRadiusChange = (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    setUpdate([Number(target.value)]);
    setValues([Number(target.value)]);
  };

  const onSwipesNeededToMatchChange = (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let swipesNeededToMatch = Number(target.value);
    setSwipesNeededToMatch(target.value);
    if (target.value === "") {
      return;
    }
    if (swipesNeededToMatch < MIN_SWIPES_NEEDED_TO_MATCH) {
      setSwipesNeededToMatch(MIN_SWIPES_NEEDED_TO_MATCH.toString());
    } else if (swipesNeededToMatch > MAX_SWIPES_NEEDED_TO_MATCH) {
      setSwipesNeededToMatch(MAX_SWIPES_NEEDED_TO_MATCH.toString());
    }
  };

  const onSwipesNeededToMatchBlur = (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let swipesNeededToMatch = Number(target.value);
    if (swipesNeededToMatch < MIN_SWIPES_NEEDED_TO_MATCH) {
      setSwipesNeededToMatch(MIN_SWIPES_NEEDED_TO_MATCH.toString());
    } else if (swipesNeededToMatch > MAX_SWIPES_NEEDED_TO_MATCH) {
      setSwipesNeededToMatch(MAX_SWIPES_NEEDED_TO_MATCH.toString());
    }
  };

  const onMaxPriceChange = (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    setMaxPrice(target.value);
  };

  const onSubmit = () => {
    // TODO: Add more validation
    if (
      address.lat &&
      address.lng &&
      update[0] &&
      maxPrice &&
      Number(swipesNeededToMatch) <= MAX_SWIPES_NEEDED_TO_MATCH &&
      Number(swipesNeededToMatch) >= MIN_SWIPES_NEEDED_TO_MATCH
    ) {
      createGroup({
        variables: {
          lat: address.lat,
          long: address.lng,
          max_price: Number(maxPrice),
          min_price: 1,
          radius: Math.round(Math.min(40000, Number(update[0]) * 1609.344)),
          swipes_needed_to_match: Number(swipesNeededToMatch),
        },
      });
    }
  };

  // TODO: Add better error loading things
  if (tokenError || groupError) {
    return <p>Error creating group. Please try again later.</p>;
  }

  if (tokenCalled) {
    return (
      <Loading
        style={{ position: "absolute", width: "100%" }}
        text="Waiting for a room to be created"
      ></Loading>
    );
  }

  if (groupCalled) {
    return (
      <Loading
        style={{ position: "absolute", width: "100%" }}
        text="Creating a group"
      ></Loading>
    );
  }

  return (
    <>
      <SEO
        title="Create Group"
        lang="en"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
      />
      <section className="main-container form-container">
        <form onSubmit={e => e.preventDefault()}>
          <div className="label-container">
            <p className="form-label">Location?</p>
            {/* TODO: Add tooltip explaining you need to allow location */}
            <Switch
              onChange={(checked: boolean) => {
                setIsChecked(checked);
              }}
              disabled={!locationPermission}
              onColor="#2186EB"
              offColor="#7B8794"
              checked={isChecked}
              activeBoxShadow="0 0 2px 2px #2186EB"
              aria-label="Use my location toggle switch"
            />
          </div>
          <div
            className="location-input-wrapper"
            style={{
              opacity: isChecked ? 0 : 1,
              height: isChecked ? 0 : "40px",
              pointerEvents: isChecked ? "none" : "auto",
              cursor: isChecked ? "none" : "pointer",
            }}
          >
            <AlgoliaPlaces
              placeholder="Enter address..."
              options={{
                appId: process.env.ALGOLIA_APP_ID,
                apiKey: process.env.ALGOLIA_API_KEY,
                language: "en",
                countries: ["us"],
                type: "address",
              }}
              onChange={({
                suggestion,
              }: {
                suggestion: { latlng: { lat: number; lng: number } };
              }) => {
                setAddress({
                  lat: suggestion.latlng.lat,
                  lng: suggestion.latlng.lng,
                });
              }}
            />
          </div>

          <div className="label-container">
            <label className="form-label" htmlFor="radius">
              Radius (mi)
            </label>
            <input
              className="radius-input"
              id="radius"
              type="number"
              value={update[0]}
              onChange={onRadiusChange}
              style={{
                backgroundColor:
                  state.theme === "light" ? "white" : "hsl(209, 18%, 30%)",
                borderColor:
                  state.theme === "light" ? "#CCC" : "hsl(209, 20%, 25%)",
                color: state.theme === "light" ? "black" : "white",
              }}
            ></input>
          </div>
          <div className="slider-wrapper">
            <RadiusSlider
              values={values}
              onChange={onChange}
              onUpdate={onUpdate}
            />
          </div>
          <div className="label-container">
            <label className="form-label" htmlFor="radius">
              Max Price
            </label>
          </div>
          <div className="max-price-container" onChange={onMaxPriceChange}>
            <label className="radio-wrapper">
              <input type="radio" value="1" name="max-price" />$
            </label>
            <label className="radio-wrapper">
              <input type="radio" value="2" name="max-price" />
              $$
            </label>
            <label className="radio-wrapper">
              <input
                type="radio"
                value="3"
                name="max-price"
                defaultChecked={true}
              />
              $$$
            </label>
            <label className="radio-wrapper">
              <input type="radio" value="4" name="max-price" />
              $$$$
            </label>
          </div>
          <div className="label-container group-match-container">
            <label
              className="form-label"
              htmlFor="group-match"
              style={{ marginRight: "8px" }}
            >
              Swipes needed to match
            </label>
            {/* TODO: Add tooltip explaining you need to allow location */}
            <input
              className="group-match-input"
              id="group-match"
              type="number"
              value={swipesNeededToMatch}
              onChange={onSwipesNeededToMatchChange}
              style={{
                backgroundColor:
                  state.theme === "light" ? "white" : "hsl(209, 18%, 30%)",
                borderColor:
                  state.theme === "light" ? "#CCC" : "hsl(209, 20%, 25%)",
                color: state.theme === "light" ? "black" : "white",
              }}
              onBlur={onSwipesNeededToMatchBlur}
            ></input>
          </div>
          <div className="button-wrapper">
            <Button
              text="Create"
              style={{ width: "100%", maxWidth: "20rem" }}
              onClick={onSubmit}
              ariaLabel="Create"
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default createGroup;
